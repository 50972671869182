<script setup>
const points = useState('points')
const { getPoint } = useDatabase()

const pageTake  = 10
const dialog    = ref(false)
const histories = ref(points.value)
const isLoading = ref(false)

const handleChangePage = async (page) => {
  isLoading.value = true
  histories.value = await getPoint({ skip: ((page < 1 ? 1 : page) - 1) * pageTake, take: pageTake })
  isLoading.value = false
}
watch(() => dialog.value, async aft => {
  isLoading.value = true
  histories.value = aft ? await getPoint({ take: pageTake }) : null
  isLoading.value = false
})
</script>

<template>
  <div>
    <button
      type="button"
      class="block"
      @click="dialog = true"
    >
      <small class="w-6 h-6 font-bold inline-flex items-center justify-center rounded-full border-4 border-double border-zinc p-1 mr-1">P</small>
      <strong>{{ points.total.toLocaleString() }}<small class="font-bold">ポイント</small></strong>
    </button>

    <default-ui-dialog
      v-model="dialog"
      title-center
      content-center
      :width="800"
    >
      <template #title>ポイント履歴</template>

      <table
        v-if="histories?.items?.length"
        class="table-list w-full text-base sm:text-lg"
      >
        <tbody>
          <tr
            v-for="(item, i) of histories.items"
            :key="i"
          >
            <td class="px-3">
              <strong class="inline sm:hidden mr-3 after:content-['：']">日付</strong>
              <span>{{ $dayjs(item.created_at).format('YYYY.MM.DD') }}</span>
            </td>
            <td class="px-3">
              <strong class="inline sm:hidden mr-3 after:content-['：']">ポイント</strong>

              <strong :class="{
                'text-indigo-500': item.points > 0,
                'text-red-500': item.points < 0,
              }">{{item.points.toLocaleString()}}pt</strong>
            </td>
            <td class="px-3">
              <strong class="inline sm:hidden mr-3 after:content-['：']">内容</strong>
              <span>{{ item.description }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else class="text-center text-zinc font-noto-serif">― 履歴がありません ―</p>

      <template #footer>
        <default-ui-pagination
          v-if="histories && histories.items.length"
          class="mx-auto"
          small
          :current-count="histories.page.currentCount"
          :current-page="histories.page.currentPage"
          :total-count="histories.page.totalCount"
          :total-pages="histories.page.totalPages"
          @change-page="handleChangePage"
        />
      </template>
    </default-ui-dialog>
  </div>
</template>