<script setup>
const { getFavorites } = useDatabase()

const pageTake  = 10
const dialog    = ref(false)
const favorites = ref(null)
const isLoading = ref(false)

const handleChangePage = async (page) => {
  isLoading.value = true
  favorites.value = await getFavorites({ skip: ((page < 1 ? 1 : page) - 1) * pageTake, take: pageTake })
  isLoading.value = false
}
watch(() => dialog.value, async aft => {
  isLoading.value = true
  favorites.value = aft ? await getFavorites({ take: pageTake }) : null
  isLoading.value = false
})
</script>

<template>
  <div>
    <button
      type="button"
      class="btn text-sm !font-noto-sans"
      @click="dialog = true"
    >
      <NuxtImg src="/icon/town.png" class="h-6 md:h-8" />
      <span class="hidden xl:inline">お気に入り店舗</span>
    </button>

    <default-ui-dialog
      v-model="dialog"
      title-center
      content-center
      :width="600"
    >
      <template #title>お気に入り店舗</template>

      <default-ui-skelton v-if="isLoading" />

      <template v-else>
        <div
          v-if="favorites && favorites.items.length"
          class="flex flex-col divide-y-2 divide-dotted divide-zinc-400"
        >
          <NuxtLink
            v-for="(favo, f) of favorites.items"
            :key="f"
            :to="'/producer/'+favo.cuid"
            class="flex gap-3 pt-5 sm:pt-3 pb-3 relative"
            @click="dialog = false"
          >
            <div
              class="w-28 aspect-[4/3] rounded-lg bg-no-repeat border bg-cover bg-center"
              :style="{backgroundImage: `url(${favo.image_files?.length ? favo.image_files[0].url : '/noimage.png'})` }"
            ></div>
            <!-- <NuxtImg :src="`/materials/${n}.jpg`" /> -->

            <div class="flex flex-col py-3">
              <p v-if="favo.name" class="py-1 text-lg">{{ favo.name }}</p>
              <p v-if="favo.catch_copy">{{ favo.catch_copy }}</p>
              <p class="text-gray mt-auto">{{ favo.product_detail }}</p>
            </div>

            <div class="w-min absolute sm:relative top-1.5 left-0 self-end ml-auto">
              <span v-if="favo.name" class="badge badge-lg badge-outlined badge-primary !px-3">
                <NuxtImg src="/icon/coins-sm.png" width="18px" />
                閲覧済み
              </span>
              <span v-else class="badge badge-lg badge-outlined badge-zinc !px-3">未閲覧</span>
            </div>
          </NuxtLink>
        </div>

        <p v-else class="text-center text-zinc font-noto-serif">― 履歴がありません ―</p>
      </template>

      <template #footer>
        <default-ui-pagination
          v-if="favorites && favorites.items.length"
          class="mx-auto"
          small
          :current-count="favorites.page.currentCount"
          :current-page="favorites.page.currentPage"
          :total-count="favorites.page.totalCount"
          :total-pages="favorites.page.totalPages"
          @change-page="handleChangePage"
        />
      </template>
    </default-ui-dialog>
  </div>
</template>