<script setup>
const { $dayjs } = useNuxtApp()
const account = useState('account')
const dialog = ref(false)
</script>

<template>
  <div>
    <button
      type="button"
      class="text-sm"
      @click="dialog = true"
    >会員情報</button>

    <default-ui-dialog
      v-model="dialog"
      title-center
      content-center
      :width="600"
    >
      <template #title>会員情報</template>

      <table class="table-list w-full">
        <tbody>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">名前</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.last_name }} {{ account?.first_name }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">名前(カナ)</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.last_name_kana }} {{ account?.first_name_kana }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">生年月日</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.birthday ? $dayjs(account?.birthday).format('YYYY年M月D日') : '' }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">性別</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.gender }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">郵便番号</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.zipcode }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">住所</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.prefecture+account?.address1+account?.address2 || '' }}<br v-if="account.building">{{  account.building }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">電話番号</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.phone }}</td>
          </tr>
          <tr>
            <th class="w-full sm:w-40 pr-0 sm:pr-3">
              <span class="badge badge-lg badge-secondary w-full font-noto-serif">メールアドレス</span>
            </th>
            <td class="pl-0 sm:pl-3">{{ account?.email }}</td>
          </tr>
        </tbody>
      </table>
    </default-ui-dialog>
  </div>
</template>