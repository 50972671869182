<script setup>
const router = useRouter()
const route = useRoute()
const emits = defineEmits(['query', 'change-page'])
const props = defineProps({
  small       : { type: Boolean, default: false },
  path        : { type: String, require: true},
  currentCount: { type: Number, require: true },
  currentPage : { type: Number, require: true },
  totalCount  : { type: Number, require: true },
  totalPages  : { type: Number, require: true },
})

const pageTransition = (page) => {
  if (props.path) {
    route.query.p = page
    router.push(route)
    emits('query', route.query)
  } else {
    emits('change-page', page)
  }
}
</script>


<template>
  <div class="mt-3 flex items-stretch divide-x rounded overflow-hidden w-min gap-1">
    <button
      v-if="totalPages >= 3"
      :disabled="currentPage == 1"
      class="btn btn-square btn-outlined btn-primary"
      :class="small ? 'btn-xs' : 'btn-sm'"
      @click="pageTransition(1)"
    >
      <Icon icon="mdi:chevron-double-left" width="25" />
    </button>
    <button
      :disabled="currentPage == 1"
      class="btn btn-square btn-outlined btn-primary"
      :class="small ? 'btn-xs' : 'btn-sm'"
      @click="pageTransition(currentPage - 1)"
    >
      <Icon icon="mdi:chevron-left" width="25" />
    </button>

    <button
      v-if="totalPages > 1"
      class="btn btn-square btn-primary"
      :class="[
        small ? 'btn-xs' : 'btn-sm',
        { 'btn-outlined': currentPage != 1 },
      ]"
      @click="pageTransition(1)"
      v-text="'1'"
    ></button>
    <span
      v-if="currentPage > 3"
      class="btn btn-square btn-text !cursor-default"
      :class="small ? 'btn-xs' : 'btn-sm'"
    ><icon icon="mdi:dots-horizontal" /></span>
    <template v-for="n of totalPages">
      <button
        v-if="n != 1 && n-1 <= currentPage && currentPage <= n+1 && totalPages != n"
        class="btn btn-square btn-primary"
        :class="[
          small ? 'btn-xs' : 'btn-sm',
          { 'btn-outlined': currentPage != n }
        ]"
        @click="pageTransition(n)"
        v-text="n"
      ></button>
    </template>
    <span
      v-if="currentPage <= totalPages - 3"
      class="btn btn-square btn-text !cursor-default"
      :class="small ? 'btn-xs' : 'btn-sm'"
    ><icon icon="mdi:dots-horizontal" /></span>
    <button
      class="btn btn-square btn-primary"
      :class="[
        small ? 'btn-xs' : 'btn-sm',
        { 'btn-outlined': currentPage != totalPages },
      ]"
      @click="pageTransition(totalPages)"
      v-text="totalPages"
    ></button>

    <button
      :disabled="currentPage == totalPages"
      class="btn btn-square btn-outlined btn-primary"
      :class="small ? 'btn-xs' : 'btn-sm'"
      @click="pageTransition(currentPage + 1)"
    >
      <Icon icon="mdi:chevron-right" width="25" />
    </button>
    <button
      v-if="totalPages >= 3"
      :disabled="currentPage == totalPages"
      class="btn btn-square btn-outlined btn-primary"
      :class="small ? 'btn-xs' : 'btn-sm'"
      @click="pageTransition(totalPages)"
    >
      <Icon icon="mdi:chevron-double-right" width="25" />
    </button>
  </div>
</template>