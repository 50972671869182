<script setup>
useHead({ bodyAttrs: { id: 'public' } })
const { getPoint, getAccount } = useDatabase()
const account = useState('account', () => ({}))
const points  = useState('points', () => ({}))

account.value = await getAccount()
points.value  = await getPoint()

const route = useRoute()
const showDrawer = ref(false)

watch(() => route.path, aft => showDrawer.value = false)
</script>

<template>
  <header>
    <div class="header-wrapper">
      <div class="header-left">
        <div class="hidden xl:block">
          <icon icon="fa6-solid:user" width="18" class="mb-1 mr-1" />
          <div class="border-b border-zinc px-1 pb-1 inline-flex items-end gap-x-3">
            <span><span class="text-lg">{{ account?.last_name }} {{ account?.first_name }}</span>さん</span>

            <default-ui-header-dialog-point-history />

            <div>|</div>

            <default-ui-header-dialog-account-info />
          </div>
        </div>

        <NuxtLink to="/" class="hidden xl:block">
          <NuxtImg src="/icon/beginner.png" width="16px" class="inline-block" />
          <span class="border-b border-zinc px-1 pb-1">初めての方へ</span>
        </NuxtLink>

        <NuxtLink to="/">
          <h1 class="inline-flex xl:hidden items-center gap-x-2 font-noto-serif text-lg sm:text-2xl text-zinc">
            <NuxtImg src="/logo.png" width="50px" />
            あんしん市場
          </h1>
        </NuxtLink>
      </div>

      <NuxtLink to="/" class="header-logo">
        <NuxtImg src="/logo.png" class="lg:w-20 xl:w-32" />
      </NuxtLink>

      <div class="header-right">
        <default-ui-header-dialog-purchase-point />

        <default-ui-header-dialog-favorite />

        <default-ui-header-dialog-search />

        <button
          type="button"
          class="btn btn-circle"
          @click="showDrawer = true"
        >
          <icon icon="mdi:menu" width="28" />
        </button>
      </div>
    </div>

    <default-ui-header-drawer v-model="showDrawer" />

  </header>

  <slot></slot>

  <footer>
  </footer>

  <default-ui-loading />
</template>