<script setup>
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: { type: Boolean, default: false }
})

const { getSettings } = useDatabase()
const { data: $account, signOut } = useAuth()
const info = useState('settings')

const links = await getSettings('drawer-link', true)
const drawerRef = ref()
onClickOutside(drawerRef, _ => emits('update:modelValue', false))

const onLogout = () => {
  if (confirm('ログアウトしますか？')) {
    signOut($account.value?.type == 'admin' ? '/admin/login' : '/login')
  }
}

</script>

<template>
  <div
    class="header-drawer-wrapper"
    :class="{ 'drawer-show': modelValue }"
  >
    <div
      ref="drawerRef"
      class="header-drawer"
      :class="modelValue ? 'translate-x-0' : 'translate-x-full'"
    >

      <div class="header-drawer-header">
        <NuxtImg
          src="/logo.png"
          class="w-12"
        />
        <h1 class="font-bold text-2xl">{{ info?.site?.name }}</h1>

        <button
          type="button"
          class="btn btn-circle !text-white !bg-[#7dbc80] ml-auto"
          @click="emits('update:modelValue', false)"
        >
          <icon icon="mdi:close" width="28" />
        </button>
      </div>

      <NuxtLink to="/" class="py-4 flex items-center justify-center gap-x-2 text-lg !no-underline border-b border-zinc">生産者登録をお考えの方</NuxtLink>

      <div class="header-drawer-menu">
        <NuxtLink
          v-for="(link, l) of links"
          :key="l"
          :to="link.value"
          class="header-drawer-menu-item"
        >{{ link.label }}</NuxtLink>

        <button
          type="button"
          class="header-drawer-menu-item text-primary bg-primary/5"
          @click="onLogout"
        >
          <icon icon="mdi:logout" />
          ログアウト
        </button>
      </div>

      <div class="header-drawer-footer">
        <NuxtLink
          v-if="$account?.producer"
          to="/"
          class="btn btn-sm btn-gray !font-noto-sans mb-3"
        >
          <icon icon="mdi:logout" />
          出店者マイページ
        </NuxtLink>

        <p class="mt-auto text-center font-noto-serif my-6 text-xl">株式会社こころざし</p>

        <p class="text-center text-sm">
          〒780-0841 高知県高知市帯屋町1丁目18-4<br>
          MAIL: anshin-ichiba@kokorozashi.group
        </p>

        <p class="mt-4 py-2 text-center text-xs text-gray">
          © Kokorozashi All Right Reserved.
        </p>
      </div>
    </div>
  </div>
</template>