<script setup>
const $payment = usePayment()
const { getPlans, getSettings } = useDatabase()

const points  = useState('points')
const plans   = await getPlans()
const payment = await getSettings('payment')

const selectedPlan = ref(null)
const dialog = ref(false)
const linkToPayment = (plan) => {
  selectedPlan.value = plan.id

  console.log($payment, payment)
  $payment[payment.service].createSession({
    plan_id: plan.id,
    success_url: location.href,
    cancel_url: location.href,
  }).then(session => {
    if (session?.url) location.href = session?.url || '/'
  }).catch(e => {
    console.error(e)
    alert('問題が発生しました。')
  }).finally(_ => {
    selectedPlan.value = null
  })
}
</script>

<template>
  <div>
    <button
      type="button"
      class="btn text-sm !font-noto-sans"
      @click="dialog = true"
    >
      <NuxtImg src="/icon/coins-lg.png" width="35px" />
      <span class="hidden xl:inline">ポイントを購入する</span>
    </button>

    <default-ui-dialog
      v-model="dialog"
      title-center
      content-center
      :width="600"
    >
      <template #title>ポイント購入</template>


      <div class="w-full text-center mb-6">
        <p class="inline-block mx-auto border-b border-zinc pb-1 px-3">
          <small class="w-6 h-6 font-bold inline-flex items-center justify-center rounded-full border-4 border-double border-zinc p-1 mr-1">P</small>
          <strong class="text-lg">{{ points.total.toLocaleString() }}<small class="font-bold">ポイント</small></strong>
        </p>
      </div>

      <div class="flex flex-col sm:flex-row items-stretch justify-center divide-y-2 sm:divide-y-0 divide-x-0 sm:divide-x-2 divide-dotted divide-zinc-400 text-center">
        <div
          v-for="(plan, p) of plans"
          :key="p"
          class="w-full sm:w-80 py-3 px-3 flex flex-col"
        >
          <NuxtImg :src="plan.image_file?.url" class="mt-auto mx-auto h-6 md:h-8" />
          <p class="block text-lg font-noto-serif">{{ plan.point?.toLocaleString() }}ポイント</p>
          <p class="block text-base font-noto-serif">({{ plan.price?.toLocaleString() }}円)</p>
          <button
            type="button"
            class="mt-4 btn btn-sm btn-primary"
            :disabled="selectedPlan"
            @click="linkToPayment(plan)"
          >
            <icon v-if="selectedPlan == plan.id" icon="mdi:selectedPlan" class="animate-spin" width="22" />
            <span v-else>購入する</span>
          </button>
        </div>
      </div>
    </default-ui-dialog>
  </div>
</template>