<script setup>
const router = useRouter()
const route = useRoute()
const keyword = ref(route.query.s || '')
const { getCategoryChildren } = useDatabase()
const categories = await getCategoryChildren()

const menu = ref(false)

const onSearch = () => {
  router.push({
    path: '/producer',
    query: { s: keyword.value },
  })
}
</script>

<template>
  <div>
    <default-ui-menu v-model="menu">
      <template #activator>
        <button
          type="button"
          class="btn text-sm !font-noto-sans"
          @click.prevent="menu = !menu"
        >
          <NuxtImg src="/icon/search.png" class="h-6 md:h-8" />
          <span class="hidden xl:inline">生産者を探す</span>
        </button>
      </template>

      <form
        class="px-3 py-4 relative border-b border-zinc-300"
        @submit.prevent="onSearch"
      >
        <input
          type="input"
          placeholder="キーワードで探す"
          v-model="keyword"
          class="input !pr-12"
        />

        <button
          type="submit"
          class="btn btn-sm btn-square btn-sm !absolute top-1/2 right-5 -translate-y-1/2"
        ><icon icon="mdi:search" width="20" /></button>
      </form>

      <div class="pb-3 max-h-80 overflow-y-auto">
        <NuxtLink
          v-for="(cat, c) of categories"
          :key="c"
          class="px-3 py-2 border-b border-zinc-300 last:border-b-0 text-lg block link hover:bg-zinc-100"
          :to="{
            path: '/producer',
            query: { c: cat.id }
          }"
        >{{ cat.name }}</NuxtLink>
      </div>
    </default-ui-menu>
  </div>
</template>