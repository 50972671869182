export function usePayment() {
  const { tokens, data } = useAuth();
  const headers = { authorization: 'Bearer '+(tokens.value?.accessToken || '') }

  // ===================================================================
  // KOMOJU
  // ===================================================================
  const komoju = {
    /** **************************************
     * セッション作成
     * @param {Object} params - パラメータ
     * @param {String} params.name - 顧客氏名
     * @param {String} params.email - 顧客メールアドレス
     * @param {Number} params.total - 支払金額(送料、手数料含む)
     * @param {String} params.return_url - 決済後にリダイレクトするURL
     * @returns {Object} - session,public_key
     ************************************** */
    createSession: (params) => {
      return new Promise((resolve, reject) => {
        $fetch('/api/payment/komoju/session', {
          method: 'POST',
          body: params,
          headers,
        }).then(resolve).catch(reject)
      })
    }
  }

  // ===================================================================
  // Stripe
  // ===================================================================
  const stripe = {
    getSetting: () => {
      return new Promise((resolve, reject) => {
        $fetch('/api/payment/stripe/setting', { headers }).then(resolve).catch(reject)
      })
    },

    /** ***********************************************************************
     * Stripeセッション作成
     * @param {Object} params - セッション作成オブジェクト
     * @param {Number} params.plan_id     - 【必須】プランID
     * @param {String} params.suucess_url - 【必須】支払い後リダイレクトURL
     * @param {String} params.cancel_url  - 【必須】キャンセルリダイレクトURL
     * @param {Number} params.account_id  - アカウントID(管理者からの代理)
     * @returns {Object}
     *********************************************************************** */
    createSession: (params) => {
      return new Promise((resolve, reject) => {
        $fetch('/api/payment/stripe/session', {
          method: 'POST',
          body: params,
          headers,
        }).then(resolve).catch(reject)
      })
    }
  }


  // ===================================================================
  // Square
  // ===================================================================
  const square = {
    getSetting: () => {
      return new Promise((resolve, reject) => {
        $fetch('/api/payment/square/setting', { headers }).then(resolve).catch(reject)
      })
    },

    /** **************************************
     * 支払い
     * @param {Object} params - パラメータ
     * @param {String} params.location_id - 地域ID
     * @param {String} params.source_id - トークン
     ************************************** */
    postPay: (params) => {
      $fetch('/api/payment/square/pay', {
        method: 'POST',
        body: params,
        headers,
      }).then(resolve).catch(reject)
    }
  }

  const fincode = {
    addCustomer: () => {},

    createSession: () => {},

    createCardSession: () => {},
  }

  return {
    komoju,
    stripe,
    square,
    fincode,
  }
}